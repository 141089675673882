<script>
  import { i18n as t } from '../localization/localization.js';
  import Nav from './common/nav.svelte';
  import Icon from './common/logo-preem.svelte';
  import { Link } from 'svelte-routing';
</script>

<div>
  <Nav icon={true} />
  <div>
    <span class="label m">
      {$t.copyright}
    </span>
    <ul>
      <li class="label m"><Link to="/privacy">{$t.privacy_policy}</Link></li>
      <li id="cookies" class="label m cky-banner-element change-cookie-preferences"><a href="#cookies">{$t.cookies}</a></li>
    </ul>
  </div>
</div>

<style>
</style>
