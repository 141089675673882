<script>
    import {onMount} from "svelte";
    import {navigate} from "svelte-routing";

    export let to;
    export let _from = undefined;
    export let replace = false;

    onMount(() => {
        if (_from) {
            to += `?redirect=${encodeURIComponent(_from)}`;
        }
        navigate(to, {replace});
    });
</script>
