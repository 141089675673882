<script>
  import config from '../config/config';

  import Mission from '../components/mission.svelte';
  import Hero from '../components/hero.svelte';
  import Team from '../components/team.svelte';
  import Loading from '../components/loading.svelte';
  import Carousel from '../components/carousel.svelte';
  $: pageTitle = 'Preem';
</script>

<svelte:head>
  <title>{pageTitle}</title>
  <meta content={pageTitle} property="og:title">
  <meta content={pageTitle} property="twitter:title">

  <link rel="apple-touch-icon" type="image/png" href="{config.assets}/images/apple-touch-icon.png">
  <meta property="og:image" content="{config.assets}/images/og.png" />
</svelte:head>

<div>
  <div class="content">
    <Hero />
    <Mission />
    <Carousel />
    <Team />
  </div>
</div>

<style lang="scss">
</style>
