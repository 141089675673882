<script>
  import { Router, Route } from 'svelte-routing';
  import Redirect from '../utils/redirect.svelte';

  import Homepage from '../views/homepage.svelte';
  import PrivacyPolicy from '../views/privacy_policy.svelte';
  import Header from '../components/header.svelte';
  import Footer from '../components/footer.svelte';
  import NotFound from '../components/not-found.svelte';

  import { onMount, onDestroy } from 'svelte';
  import { globalHistory } from 'svelte-routing/src/history';

  import { currentPath, lastPath } from '../stores/nav_store';

  let unsub;
//    let homepage = '';

  // Svelte routing only exposes the current path to Route components. Since we need access to the current path in our header/footer,
  // which are not Route components, we need to keep track of the current path elsewhere.
  onMount(() => {
    unsub = globalHistory.listen(({location, action}) => {
      $lastPath = $currentPath;
      $currentPath = location.pathname;

//            homepage = $currentPath === "/tv" ? "homepage" : "";
    });
//        homepage = $currentPath === "/tv" ? "homepage" : "";
  });

  onDestroy(() => {
    unsub();
  });

</script>

<div id="doc">
  <Router basepath="">
    <header>
      <Header />
    </header>
    <main>
      <Route path="/" component={Homepage} />
      <Route path="/privacy" component="{PrivacyPolicy}"/>
      <Route path="/tv">
         <Redirect to="/" />
       </Route>
       <Route path="/tv/*">
         <Redirect to="/" />
       </Route>
      <Route fallback component={NotFound} />
    </main>
    <footer>
      <Footer/>
    </footer>
  </Router>
</div>
<div id="shader"></div>

<style>
</style>
