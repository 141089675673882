import Config from '../config/config.js';

const { preem_api, environment } = Config;

export async function submitUserContact(name, email) {
  const response = await fetch(`${preem_api}/tv/userContact`, {
    method: 'POST',
    headers:{
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name,
      email
    })
  });
}