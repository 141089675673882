<script>
  import { onMount } from 'svelte';
  import carousel from '../stores/carousel';
  import Pin from '../components/common/pin.svelte';
  import Chevron from '../components/common/chevron.svelte';

  onMount(() => {
    let sections = gsap.utils.toArray('.preem-carousel .panel');

    // horizontal scrolling plugin
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: '.preem-carousel .container',
        pin: true,
        scrub: 1,
//        snap: 1 / (sections.length - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => '+=' + document.querySelector('.container').offsetWidth
      }
    });
  });

  // scrolls the browser viewport to the first node matching a given selector on the page
  const scrollTo = function(selector, duration) {

    if (arguments.length === 1) {
      duration = 200;
    }

    const
      n = document.querySelector(selector),
      rect = n.getBoundingClientRect(),
      y = rect.top + window.pageYOffset;

    const
      timing = function(t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t },
      startingY = window.pageYOffset,
      diff = y - startingY;

    let start = performance.now();

    window.requestAnimationFrame(function step(timestamp) {

      const
        time = timestamp - start,
        percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * timing(percent));
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  const clickTop = (e) => {
    e.preventDefault();
    scrollTo('#top', 1000);
  };

  const clickTeam = (e) => {
    e.preventDefault();
    scrollTo('#team', 1000);
  };
</script>
<div class="preem-carousel">
  <div class="container" style="width: {carousel.length * 100}vw;">
    {#each carousel as tagline}
      <div class="panel">
        <dl>
          <dt class="title xxl"><span>Has your ride ever…</span></dt>
          <dd class="title xxl">
            <span>{tagline}</span>
            <Pin />
          </dd>
        </dl>
      </div>
    {/each}

    <div class="nav">
      <ul>
        <li class="prev"><a href="#top" class="chevron" on:click={clickTop}><span>Top <Chevron dir="up" /></span></a></li>
        <li class="next"><a href="#team" class="chevron" on:click={clickTeam}><span>The Team <Chevron dir="down" /></span></a></li>
      </ul>
    </div>
    <div class="bg"></div>
  </div>

</div>

<style lang="scss">
@import "../scss/components/carousel.scss";
</style>