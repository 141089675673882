<script>
    import {currentPath} from '../stores/nav_store';

    $: {
        // Rerun on every nav event.
        if (typeof gtag !== 'undefined') {
            gtag("config", 'G-32R0Y93HLF', {
                page_path: $currentPath,
            });
        }

        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'pageview',
                page: {
                    path: $currentPath,
                }
            });
        }
    }
</script>

<noscript>
  <iframe title="Google Tag Manager" src="https://www.googletagmanager.com/ns.html?id=GTM-W3K38RC"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
</noscript>

