<script>
  import TeamMember from '../components/private/team-member.svelte';
  import Chevron from '../components/common/chevron.svelte';
  import team from '../stores/team';
</script>

<section id="team">
  <div>
    <div class="hd">
      <h3 class="title xl">The Team</h3>
    </div>
    <p class="body xl medium">Meet our team of brand builders, community creators, technologists, and passionate cyclists.</p>
    <ul>

      {#each team as member}
        <li>
          <TeamMember person={member} />
        </li>
      {/each}

      <li>
        <a href="https://www.linkedin.com/company/gopreem/jobs/" title="We’re hiring" class="chevron " target="_blank">
          <em><strong>We’re hiring!</strong></em>
          <span>
            Join the Team
            <Chevron dir="right" />
          </span>
        </a>
      </li>
    </ul>
  </div>
</section>

<style lang="scss">
@import "../scss/components/team.scss";
</style>