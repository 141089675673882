<script>
  import { i18n as t } from '../localization/localization.js';
  import { Link } from 'svelte-routing';
  import { submitUserContact } from '../services/user_service';
  import { isContactSubmitted } from '../stores/contact_store';

  import Chevron from './common/chevron.svelte';

  let name = '';
  let email = '';

  let nameError = null;
  let emailError = null;
  let errorSubmitting = false;
  let showThankYou = false;
  let processing = false;

  $: contactSubmitted = $isContactSubmitted;

  // return boolean, true if valid name; false if not
  function isName(name) {
    return name.trim().length > 0 // allow non-ascii characters
  }

  // return boolean, true if valid email; false if not
  function isEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === '') {
      nameError = 'First Name is required.';
    } else {
      nameError = !isName(name) ? 'Please enter a valid name.' : null;
    }

    if (email === '') {
      emailError = 'Email address is required.';
    } else {
      emailError = !isEmail(email) ? 'Please enter a valid email address.' : null;
    }

    if (!nameError && !emailError) {
      processing = true;
      try {
        await submitUserContact(name, email);
        isContactSubmitted.updateContactState();
        showThankYou = true;
      } catch (e) {
        console.error(e);
        errorSubmitting = true;
      }
      processing = false;
    }
  };
</script>


<div class="preem-rect">
  <h3 class="title m">Don’t Miss the Early Move</h3>
  <p class="body l">
    Come join our early adopter program and stay on the pointy-end of all things Preem.
  </p>

  {#if showThankYou}
    <div>
      <h4 class="title l">Success!</h4>
      <p class="body m">Thank you for staying in touch!</p>
    </div>
  {:else}
    {#if errorSubmitting}
      <div>There was an error submitting…</div>
    {:else}
      {#if !contactSubmitted}
        <form id="preem-chimp" autocomplete="off" on:submit={e => handleSubmit(e)}>
          <fieldset class="input">
            <label>
              <input type="text" bind:value={name} required placeholder="{$t.input_first_name}">
              {#if nameError}
                <div class="error">*{nameError}</div>
              {/if}
            </label>
            <label>
              <input type="email" bind:value={email} required placeholder="{$t.input_email}">
              {#if emailError}
                <div class="error">*{emailError}</div>
              {/if}
            </label>
          </fieldset>
          <fieldset class="action">
            <button disabled={processing} type="submit">{$t.mailchimp_cta}</button>
            <p class="body s">
              <span>Your information is safe with us!</span>
              <Link to="/privacy#doc" title="Read our Privacy Policy" class="chevron">
              <span>
                Privacy Policy
                <Chevron dir="right" />
              </span>
              </Link>
            </p>
          </fieldset>
        </form>
      {:else}
        <div>
          <h4 class="title l">Success!</h4>
          <p class="body m">Thank you for staying in touch!</p>
        </div>
      {/if}
    {/if}
  {/if}
</div>
<style lang="scss">
  @import "../scss/components/early-move.scss";
</style>