<script>
  import { i18n as t } from '../localization/localization.js';
  import { Link } from 'svelte-routing';
  import config from '../config/config.js';

  import Nav from './common/nav.svelte';

  let menu_open = false;

  function toggle_menu(e) {
    e.preventDefault();
    menu_open = !menu_open;
    if (menu_open) {
      document.body.classList.add('shaded');
    } else {
      document.body.classList.remove('shaded');
    }
  }

  $: menu_class = menu_open ? 'open' : '';

  function close_modal(e) {
//     if (
//       menu_open &&
//       e.target.className.indexOf('mobile-menu') < 0 &&
//       e.target.className.indexOf('nav-link-mobile') < 0 &&
//       e.target.className.indexOf('nav-highlight-mobile') < 0 &&
//       e.target.className.indexOf('nav-link-mobile-label') < 0
//     ) {
      menu_open = false;
      document.body.classList.remove('shaded');
//     }
  }
</script>

<svelte:body on:click={close_modal} />

<div class="mobile {menu_class}" id="mobile">
  <a href="#mobile" on:click|stopPropagation={toggle_menu} class="preem-burger">
    <span></span>
    <span></span>
    <span></span>
  </a>
  <div class="menu">
    <Nav icon={false} />
  </div>
</div>

<style></style>
