<script>
  export let person;
  import config from '../../config/config';
</script>

<dl id="{person.id}">
  <dt class="title s">
    <img src="{person.image}" alt="{person.name.first} {person.name.last}">
    {person.name.first} {person.name.last}
  </dt>
  <dd class="position label m color medium">
    {person.position}
  </dd>
  {#if person.social}
  <dd class="social">
    <ul class="preem-social">
      {#if person.social.strava}
        <li>
          <a href="https://www.strava.com/athletes/{person.social.strava}" class="strava" title="{person.name.first} on Strava" target="_blank">
            <svg><title>Strava</title><use href="{config.assets}/svg/sprite.svg#strava"></use></svg>
          </a>
        </li>
      {/if}
      {#if person.social.linkedin}
        <li>
          <a href="https://www.linkedin.com/in/{person.social.linkedin}" class="linkedin" title="{person.name.first} on LinkedIn" target="_blank">
            <svg><title>LinkedIn</title><use href="{config.assets}/svg/sprite.svg#linkedin"></use></svg>
          </a>
        </li>
      {/if}
    </ul>
  </dd>
  {/if}
</dl>

<style lang="scss">
  @import "../../scss/components/_team-member.scss";
</style>
