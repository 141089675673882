<script>
//  import { Link } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { i18n as t } from '../../localization/localization.js';
//  import { currentPath } from '../stores/nav_store.js';
  import Icon from '../common/logo-preem.svelte';

  import NavList from '../common/nav-list.svelte';

  // should the preem icon be displayed?
  export let icon;

//  $: path = $currentPath;
//  $: console.log('path', currentPath);

//   onMount(async () => {
//   });
</script>

{#if icon}
  <nav>
    <a href="/" title="Home" class="logo" tabindex="-1">
      <Icon />
    </a>
    <NavList />
  </nav>
{:else}
  <div>
    <NavList />
  </div>
{/if}

<style>
</style>
