import { writable } from 'svelte/store';

const localStorageKey = 'user-contact';

const {subscribe, set, update} = writable(localStorage.getItem(localStorageKey)
    ? localStorage.getItem(localStorageKey) === 'true' : false);

const updateContactState = () => {
  localStorage.setItem(localStorageKey, 'true');
  set(true);
};

function createContactStore() {
  return {
    subscribe,
    updateContactState: updateContactState
  };
}

export const isContactSubmitted = createContactStore();