import config from '../config/config.js';

let root = `${config.assets}/images/team`;

let data = [
  {
    id: 'david-norris',
    name: {
      first: 'David',
      last: 'Norris'
    },
    social: {
      strava: '13443',
      linkedin: 'davidnorris430'
    },
    image: `${root}/david-norris.jpg`,
    position: 'Co-Founder & CEO'
  },
  {
    id: 'troy-busot',
    name: {
      first: 'Troy',
      last: 'Busot'
    },
    social: {
      strava: '171841',
      linkedin: 'troybusot'
    },
    image: `${root}/troy-busot.jpg`,
    position: 'CTO'
  },
  {
    id: 'sam-christian',
    name: {
      first: 'Sam',
      last: 'Christian'
    },
    social: {
      strava: '794602',
      linkedin: 'samchristian'
    },
    image: `${root}/sam-christian.jpg`,
    position: 'CFO'
  },
  {
    id: 'dena-grablowsky',
    name: {
      first: 'Dena',
      last: 'Grablowsky'
    },
    social: {
      linkedin: 'denagrablowsky'
    },
    image: `${root}/dena-grablowsky.jpg`,
    position: 'Head of Human Resources'
  },
  {
    id: 'michael-hurley',
    name: {
      first: 'Michael',
      last: 'Hurley'
    },
    social: {
      strava: '598423',
      linkedin: 'mp-hurley'
    },
    image: `${root}/michael-hurley.jpg`,
    position: 'Head of UX'
  },
  {
    id: 'austin-venhuizen',
    name: {
      first: 'Austin',
      last: 'Venhuizen'
    },
    social: {
      strava: '321505',
      linkedin: 'austin-venhuizen-67777028'
    },
    image: `${root}/austin-venhuizen.jpg`,
    position: 'Head of Product'
  },
  {
    id: 'michael-friedberg',
    name: {
      first: 'Michael',
      last: 'Friedberg'
    },
    social: {
      strava: '195419',
      linkedin: 'michael-friedberg-aa614ba1'
    },
    image: `${root}/michael-friedberg.jpg`,
    position: 'Director of Content'
  }
];

export default data;