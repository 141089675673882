<script>
  import config from '../../config/config';
</script>
<ul>
  <li class="home active">
    <a href="/" title="About">
      About
      <span />
    </a>
  </li>
  <li class="contact">
    <a href="mailto:{config.contact}" title="Contact Us">
      Contact Us
      <span />
    </a>
  </li>
</ul>
