<script>
  // one of 'left', 'right', 'up', or 'down'
  export let dir;

  // colour
  export let fill;
</script>

<svg class="chevron" aria-hidden="true" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fill={fill}>
  {#if dir === 'left'}
    <path d="M14.71 6.71C14.32 6.32 13.69 6.32 13.3 6.71L8.71 11.3C8.32 11.69 8.32 12.32 8.71 12.71L13.3 17.3C13.69 17.69 14.32 17.69 14.71 17.3C15.1 16.91 15.1 16.28 14.71 15.89L10.83 12L14.71 8.12C15.1 7.73 15.09 7.09 14.71 6.71V6.71Z"/>
  {:else if dir === 'up'}
    <path d="M8.11997 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.70998C12.31 8.31998 11.68 8.31998 11.29 8.70998L6.69997 13.3C6.30997 13.69 6.30997 14.32 6.69997 14.71C7.08997 15.09 7.72997 15.1 8.11997 14.71V14.71Z"/>
  {:else if dir === 'down'}
    <path d="M8.11997 9.29L12 13.17L15.88 9.29C16.27 8.9 16.9 8.9 17.29 9.29C17.68 9.68 17.68 10.31 17.29 10.7L12.7 15.29C12.31 15.68 11.68 15.68 11.29 15.29L6.69997 10.7C6.30997 10.31 6.30997 9.68 6.69997 9.29C7.08997 8.91 7.72997 8.9 8.11997 9.29V9.29Z"/>
  {:else}
    <path d="m9.29 6.71c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01z"/>
  {/if}
</svg>