const dictionary = {
    // Generic
    click_here: "Click Here",
    submit: "Submit",
    save: "Save",
    // Blue Box
//    blue_box_header: "Your ride is alive and it knows you can play hooky from work Tuesday afternoon.",
    blue_box_huh: "Huh?",
    input_first_name: "First Name",
    input_email: "Email",
    mailchimp_cta: "Preem My Ride",
    safe_information: "Your information is safe with us!",
    // Top-Nav
//    preem_show: "Preem Show",
//    educational: "Educational",
//    inspirational: "Inspirational",
//    lifestyle: "Lifestyle",
//    racing: "Racing",
//    personalities: "Personalities",
    contact: "Contact Us",
    about: "About",
    tv: "TV",
    // Footer
    copyright: "©2021 Preem. All rights reserved",
    terms_and_conditions: "terms & conditions",
    privacy_policy: "Privacy",
    cookies: "Cookies",
    // Homepage
    todo_hello: name => `Hello, ${name}!`,
    homepage_lead_in: "Preem TV",
    homepage_intro: "is here to celebrate cycling and the people that ride, taking you on a journey showcasing diverse disciplines, backgrounds, and locations from around the globe.",
    homepage_loading: "Loading...",
    homepage_error_loading: "There was an error loading the latest videos.",
    homepage_no_videos_message: "There are currently no videos. Check back later!",
    error_loading: "There was an error loading.",

    //playlist page
//    videoLoadFailedError: "Failed to load videos.",
//    playlistNoVideoError: "There are no currently no videos in this playlist!",
    // Play Video
    ep: "Ep.",

    //Personalities
//    personalities_error_loading: "Error loading personalities."
};

export default dictionary;