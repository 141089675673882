import {derived, readable} from "svelte/store";
import en_US from "./locales/en_US.js";

const dict = {
    en_US
};

export const lang = readable(null, set => {
    // TODO: locale preference will be come from API in the future
    set("en_US");

    return () => {
    };
});

export const i18n = derived(lang, $lang => dict[$lang]);
