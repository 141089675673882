<script>
  import Nav from './common/nav.svelte';
  import MobileMenu from './mobile_menu.svelte';
  let y = 0;

  $: {

    if (y > 40) {
      document.body.classList.add('scrolled');

    // If this number is too close to the one above,
    // the change in image size affecting the page scroll position
    // causes an infinite rendering loop
    } else if (y < 1) {
        document.body.classList.remove('scrolled');
    }
  }
</script>

<svelte:window bind:scrollY={y}/>

<div>
  <Nav icon={true} />
  <MobileMenu />
</div>
