
let data = [
  'Given you the low-down on what your friends are up to?',
  'Helped you ride like you’re home even when you’re away?',
  'Suggested you bring your rain cape?',
  'Shown you new roads in your own backyard?',
  'Kept you in the loop for rides in your area?',
  'Been more about riding and less about planning?',
  'Given you the low-down on what your friends are up to?'
];

export default data;